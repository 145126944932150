import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from "../Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import numeral from "numeral";
import ImageUri from '../ImageUri';
import moment from 'moment';
UIkit.use(Icons);

export default class ConsultCampaign extends Component {
  state = {
    list: [],
    listShow: [],
    dateStart: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    dateStop: moment().format('YYYY-MM-DD'),
    keywordPatient: ''
  };

  componentDidMount() {
    this.visitNoteGet();
  }

  listShowRender = () => {
    if(this.state.keywordPatient == '') {
      this.setState({listShow: this.state.list});
    } else {
      this.setState({listShow: this.state.list.filter((data)=>{

        return (data.name.includes(this.state.keywordPatient) === true && data.lastname.includes(this.state.keywordPatient));
      })})
    }
  }

  visitNoteGet = async () => {
    var _response = await Api.consult("VisitNoteList", {
      dateStart: this.state.dateStart,
      dateStop: this.state.dateStop,

    });

    this.setState({
      list: _response.data || []
    }, this.listShowRender);
  };

  render() {
    return (
      <div className={this.props.paddingRemove === true ? '' : 'uk-padding'}>
        <div className={"uk-card uk-card-default uk-padding-small" + (
          this.props.paddingRemove === true ? '' : ' uk-margin-top'
        )}>
          <div className="d-card-header">
            <div className="d-card-header-left">บันทึกการดูแล (Visit Note)</div>
            <div className="d-card-header-right"></div>
          </div>
          <div className="uk-grid uk-grid-small uk-flex-middle" >
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                  วันที่
                </div>
                <div className="uk-width-expand">
                  <input type="date" className="uk-input" value={this.state.dateStart} onChange={(e)=>this.setState({dateStart: e.target.value})} />
                </div>
              </div>
            </div>
            <div className="uk-width-expand@s uk-width-1-1 uk-margin-small-bottom">
              <div className="uk-grid uk-grid-small uk-flex-middle">
                <div className="uk-width-1-6">
                  ถึง
                </div>
                <div className="uk-width-expand">
                  <input type="date" className="uk-input" value={this.state.dateStop} onChange={(e)=>this.setState({dateStop: e.target.value})} />
                </div>
              </div>
            </div>
            <div className="uk-width-auto@s uk-width-1-1 uk-margin-small-bottom uk-text-right">
              <button className="uk-button uk-button-primary" onClick={this.visitNoteGet}>ค้นหา</button>
            </div>
            <div className="uk-width-1-1 uk-margin-small-bottom">
              
              <input type="text" className="uk-input" placeholder="ค้นหาชื่อคนไข้" defaultValue={this.state.keywordPatient} onChange={(e)=>this.setState({keywordPatient: e.target.value}, this.listShowRender)} />
            </div>
          </div>
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-divider">
              <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่บันทึก</th>
                <th>ชื่อนามสกุลคนไข้</th>
                <th>เลข HN (ถ้ามี)</th>
                <th>รายละเอียด</th>
              </tr>
              </thead>
              <tbody>
                {this.state.listShow.length === 0 ? 
                <tr><td colSpan="100%" className="uk-padding-small uk-text-center">ไม่พบรายการ</td></tr> 
                  :

                this.state.listShow.map((data, index) => {
                  return <tr key={`rowCampaign${index}`}>
                    <td>{index+1}</td>
                    <td>{moment(data.dateadd).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{`${data.nameprefix} ${data.name} ${data.lastname}`}</td>
                    <td>{data.hn}</td>
                    <td>
                      <div>{data.detail}</div>
                      {data.image && <div className="uk-margin-small-top" uk-lightbox="">
                          <a href={ImageUri(data.image)}>
                            <img
                              uk-img=""
                              src={ImageUri(data.image)}
                              width="50"
                            />
                          </a>
                        </div>}
                            
                    </td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
